import { createReduxModule } from 'hooks-for-redux'
import { Organization, OrgType, trans, TranslationGroup } from 'lib/types'
import { getAllOrganizations, getOrganization, createOrUpdateOrg, deleteOrg as apiDeleteOrg } from './api'
import * as NavState from './NavState'
import * as ChildOrgMap from './ChildOrgMap'
import { RateLimitedTimer } from 'lib/utils'
import * as PopUpNotifications from './PopUpNotifications'
import { getRecordsById } from './modelUtils'
const deepEqual = require('fast-deep-equal')

interface OrgsState {
  orgs: Organization[]
  orgsById: { [key: string]: Organization }
  loading: boolean
  error?: any
}

const initialState: OrgsState = {
  orgs: [],
  orgsById: {},
  loading: true,
}

const setOrganizationsReducer = (state: OrgsState, orgs: Organization[]) => ({
  ...state,
  orgs: (orgs || []).sort((a, b) => a.name.localeCompare(b.name)),
  orgsById: getRecordsById<Organization>(orgs),
})

export const [use, { setOrganizations, clearLoading, setError, updateOrg }, store] = createReduxModule(
  'orgs',
  initialState,
  {
    setOrganizations: setOrganizationsReducer,
    updateOrg: (state: OrgsState, updatedOrg: Organization): OrgsState =>
      deepEqual(updatedOrg, state.orgsById[updatedOrg.id])
        ? state
        : setOrganizationsReducer(
            state,
            state.orgs.find(org => org.id === updatedOrg.id)
              ? state.orgs.map(org => (org.id === updatedOrg.id ? updatedOrg : org))
              : [...state.orgs, updatedOrg],
          ),
    clearLoading: (state: OrgsState) => ({ ...state, loading: false }),
    setError: (state: OrgsState, error: any) => ({ ...state, error }),
  },
)

export const useSelectedOrg = (): Organization | undefined => {
  const orgsById = use(({ orgsById }) => orgsById)
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  return orgsById[selectedOrgId]
}

export const useRootOrgs = (): Organization[] => {
  const parentOrgMap = ChildOrgMap.use(({ parentOrgMap }) => parentOrgMap)
  const orgs = use(({ orgs }) => orgs)
  return orgs.filter(({ id }) => !parentOrgMap[id])
}

const reloadRateLimiter = new RateLimitedTimer()

/* reload the specified org to get current stats; Note: rate-limited to one call per org per 5 seconds. */
export const reloadOrg = (orgId: string) => {
  if (process.env.NODE_ENV === 'test') return getOrganization(orgId).then(updateOrg)
  return reloadRateLimiter.timeout(5000, orgId, () => getOrganization(orgId).then(updateOrg))
}

export const setErrorWithNotificationAndRethrow = (error: any) => {
  if (error.request?.status >= 500) {
    PopUpNotifications.fireErrorObject(error)
    setError(error)
  }
  clearLoading()
  throw error
}

/** Fetches the list of Organization visible to the current user and stores them in the Organizations H4R state. Super Admins can see all Organizations. */
export const reload = () =>
  Promise.resolve()
    .then(getAllOrganizations)
    .then(setOrganizations)
    .then(clearLoading, setErrorWithNotificationAndRethrow)

const doUpdateAction = (f: any) => {
  return Promise.resolve()
    .then(f)
    .then(result => {
      reload()
      return result
    }, setErrorWithNotificationAndRethrow)
}

export const isGroup = (org: Organization) => (org?.orgType ? org.orgType === OrgType.Group : false)
export const isProperty = (org: Organization) => (org?.orgType ? org.orgType === OrgType.Property : false)

/** Update an specific organization, them refresh the current H4R state. Super Admins can see all Organizations. */
export const createOrUpdate = (organization: any): Promise<Organization> =>
  // @ts-ignore
  doUpdateAction(() => createOrUpdateOrg(organization))

// del instead of delete; delete is a reserved word
export const deleteOrg = (orgId: string) => doUpdateAction(() => apiDeleteOrg(orgId))

export const getOrg = (orgId: string): Organization => store.getState().orgsById[orgId]

export type FlattenedOrg = {
  id: string
  name: string
  lat?: number
  long?: number
  geofencingEnabled?: boolean
  responderRadiusInMiles?: number
  orgType: OrgType
  image: string
  logoUrl: string
  phone: string
  proximityUuidName: string
  proximityUuidValue: string
  street1: string
  street2: string
  city: string
  state: string
  zipcode: string
  tzOffset?: number
  country?: string
  props?: any
}

const getProximityUuid = (name: string, value: string) => (value ? { name: name || '', value } : null)

// @ts-ignore
export const unflattenOrg = (flattenedOrg: FlattenedOrg): Organization => {
  const {
    id,
    name,
    lat,
    long,
    geofencingEnabled,
    responderRadiusInMiles,
    orgType,
    image,
    logoUrl,
    phone,
    proximityUuidName,
    proximityUuidValue,
    street1,
    street2,
    city,
    state,
    zipcode,
    tzOffset, 
    country
  } = flattenedOrg
  return {
    id,
    name,
    lat,
    long,
    geofencingEnabled,
    responderRadiusInMiles,
    orgType,
    props: {
      ...flattenedOrg?.props,
      image,
      logoUrl,
      phone,
      proximityUuid: getProximityUuid(proximityUuidName, proximityUuidValue),
      address: { street1, street2, city, state, zipcode, country },
      
    },
    tzOffset
  }
}

export const flattenOrg = (org: Organization): any => {
  const orgProps = org.props || {}
  const address = orgProps.address || {}
  const { name: proximityUuidName, value: proximityUuidValue } = org.props.proximityUuid || {}
  return {
    ...address,
    ...orgProps,
    ...org,
    proximityUuidName,
    proximityUuidValue,
  }
}

export const getSelectedOrgName = () => {
  const translation: TranslationGroup = trans.common()
  return getOrg(NavState.store.getState().selectedOrgId)?.name || translation.all_properties
}
