/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import { Button, IconButton, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useHistory } from 'react-router-dom'
import { ConditionalRender, StyledInput, StyledInputErrorLabel } from 'components/widgets'
import { PopUpNotifications } from 'models'
import { loginUser, registerUser } from 'models/api'
import { isValidEmail } from 'models/modelUtils'
import { AuthProvider, DASHBOARD_MAIN_PATH, JWT_TOKEN } from 'lib/constants'
import { authButtonStyles, authInputFieldStyles, forgotPasswordStyles } from 'styles'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'

interface Props {
  sentEmail: string
  handleForgotPassword: Function
  lookupCallback: Function
  provider: string | null
}

export function LoginForm({ sentEmail, handleForgotPassword, lookupCallback, provider }: Props) {
  const history = useHistory()
  const [email, setEmail] = useState(sentEmail)
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const inputRef = useRef<HTMLElement>(null)
  const translation: TranslationGroup = trans.merge(TranslationKey.AUTH)

  useEffect(() => {
    if (inputRef.current !== null) {
      const ele = inputRef.current.children[0] as HTMLInputElement
      ele.focus()
    }
  }, [])

  useEffect(() => {
    if (email.length) {
      if (!isValidEmail(email)) {
        setEmailError(true)
      } else {
        setEmailError(false)
      }
    }
  }, [email])

  function handleClickShowPassword() {
    setShowPassword(!showPassword)
  }

  function handleLookup() {
    if (!isValidEmail(email)) {
      return setEmailError(true)
    }
    lookupCallback(email)
  }

  function handleAuth() {
    const authService = provider === null ? registerUser : loginUser
    authService(email, password)
      .then(token => {
        setTokenAndRedirect(token)
      })
      .catch(() => PopUpNotifications.fireError({ content: translation.login_unsuccessful }))
  }

  function setTokenAndRedirect(token: string) {
    if (token) {
      // I moved this setter to the handleLogin function in api/auth
      // localStorage.setItem(JWT_TOKEN, token)
      if (localStorage.getItem(JWT_TOKEN)) history.push(DASHBOARD_MAIN_PATH)
    }
  }

  return (
    <>
      <StyledInput
        css={{ ...authInputFieldStyles, marginTop: `23px` }}
        ref={inputRef}
        id={`email`}
        disableUnderline
        fullWidth
        type={`email`}
        placeholder={translation.email}
        readOnly={provider == AuthProvider.IDENTITY}
        value={email}
        onKeyDown={e => {
          if (e.key === 'Enter') handleLookup()
        }}
        onChange={e => setEmail(e.target.value.replace(/\s/g, ''))}
        inputProps={{ maxLength: 160 }}
      />
      <ConditionalRender condition={provider === AuthProvider.UNKNOWN}>
        <StyledInputErrorLabel>{emailError ? translation.email_error : ' '}</StyledInputErrorLabel>
        <Button variant={`contained`} css={authButtonStyles} onClick={handleLookup}>
          {translation.sign_in}
        </Button>
      </ConditionalRender>

      <ConditionalRender condition={provider == AuthProvider.IDENTITY}>
        <form
          onSubmit={e => {
            e.preventDefault() // Prevent default browser behavior
            handleAuth() // Call your authentication function
          }}
        >
          <StyledInput
            css={{ ...authInputFieldStyles, marginTop: `30px` }}
            id={`password`}
            disableUnderline
            fullWidth
            type={showPassword ? `text` : `password`}
            placeholder={translation.password}
            value={password}
            onChange={e => setPassword(e.target.value.replace(/\s/g, ''))}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={translation.toggle_password}
                  onClick={handleClickShowPassword}
                  onMouseDown={event => event.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Button variant="text" css={forgotPasswordStyles} onClick={() => handleForgotPassword()}>
            {translation.forgot_password}
          </Button>
          <Button variant="contained" css={authButtonStyles} type="submit" fullWidth>
            {translation.sign_in}
          </Button>
        </form>
      </ConditionalRender>
    </>
  )
}
