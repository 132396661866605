import jwtDecode, { JwtPayload } from 'jwt-decode'
import { JWT_TOKEN, GRANTS } from 'lib/constants'
import { JWTResponse, UserGrant } from 'lib/types'
import { Users } from 'models'

function token_decodes(): boolean {
  const token = localStorage.getItem(JWT_TOKEN)
  if (!token) return false
  try {
    jwtDecode(token)
  } catch (e) {
    return false
  }
  return true
}

export function isSuperAdmin(): boolean {
  if (!token_decodes()) return false
  return Users.store.getState().currentUser?.attributes.superAdmin === true
}

export function hasOrgGrant(grant_name: string, orgId?: string): boolean {
  if (!token_decodes()) return false
  const userGrants: UserGrant[] = Users.store.getState().currentUser?.attributes.grants || []
  const orgUserGrants = userGrants.filter(g => g.org_id === orgId)
  return orgUserGrants.filter(g => g.name === grant_name).length > 0
}

export function hasGrantAnywhere(grant_name: string): boolean {
  if (!token_decodes()) return false
  const userGrants: UserGrant[] = Users.store.getState().currentUser?.attributes.grants || []
  return userGrants.filter(g => g.name === grant_name).length > 0
}

export function isAdmin(org?: string | null | undefined): boolean {
  if (isSuperAdmin()) return true
  if (org !== null && org !== undefined) {
    return hasOrgGrant(GRANTS.ADMIN, org)
  }
  return false // Handle the case when org is null or undefined
}

export function isAdminAnywhere(): boolean {
  if (isSuperAdmin()) return true
  return hasGrantAnywhere(GRANTS.ADMIN)
}

export function isPortalUser(org?: string): boolean {
  if (isSuperAdmin()) return true
  return hasOrgGrant(GRANTS.PORTAL_USER, org)
}

export function isInstaller(org?: string | null | undefined): boolean {
  if (isSuperAdmin()) return true
  if (org !== null && org !== undefined) {
    return hasOrgGrant(GRANTS.INSTALLER, org)
  }
  return false // Handle the case when org is null or undefined
}

export function isOnlyInstaller(orgId: string | null) {
  if (orgId !== null) {
    if (hasOrgGrant(GRANTS.DISPATCHER, orgId) || 
    hasOrgGrant(GRANTS.RESPONDER, orgId) || 
    hasOrgGrant(GRANTS.ADMIN, orgId) || 
    hasOrgGrant(GRANTS.PORTAL_USER, orgId)) {
      return false
    } else {
    return hasOrgGrant(GRANTS.INSTALLER, orgId)
    }
  }
  return undefined
}

export function isResponder(org?: string): boolean {
  if (isSuperAdmin()) return true
  return hasOrgGrant(GRANTS.RESPONDER, org)
}

export function isMe(user_id: string): boolean {
  const token = getUserJWT()
  if (!token) return false
  try {
    const decoded = jwtDecode(token) as any
    const sub = JSON.parse(decoded.sub)
    return user_id === sub.user_id
  } catch (e) {
    return false
  }
}

export function isMyEmail(email: string): boolean {
  const token = getUserJWT()
  if (!token) return false
  try {
    const decoded = jwtDecode(token) as any
    const sub = JSON.parse(decoded.sub)
    return email === sub.email
  } catch (e) {
    return false
  }
}

export function isDispatcher(org?: string): boolean {
  return isResponder(org) && isPortalUser(org)
}

export function getUserJWT() {
  return localStorage.getItem(JWT_TOKEN)
}

export function getDataFromJWT() {
  const token = localStorage.getItem(JWT_TOKEN)
  if (token) {
    const decoded = jwtDecode<JwtPayload>(token)
    if (decoded.sub) {
      let JWT_resp: JWTResponse = JSON.parse(decoded.sub)
      return JWT_resp
    }
    return null
  }
  return null
}
