/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { useState } from 'react'
import styled from '@emotion/styled'
import jwt_decode from 'jwt-decode'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { getUnixTime } from 'date-fns'
import { postAlertRequest } from 'models/api/rest'
import { DetailsDrawer, PopUpNotifications, Orgs } from 'models'
import { JWT_TOKEN, ALERT_API_ALERT_EVENT } from 'lib/constants'
import { v4 as getUuidv4 } from 'uuid'
import { fontWeight, textAlign, textTransform } from 'styles'
import { getAppColors, UPDATED_BY_DISPATCHER } from 'lib/constants'
import { getDataFromJWT } from 'lib/utils/auth'
import { AlertSession, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { ACTION_TYPE } from 'lib/constants/keys'
import { StyledDialogTitle } from 'components/legacy-poorly-defined/Common'
import { updateAlertSession } from 'models/AlertSessions'
import { sleep } from 'lib/utils/sleep'

const dialogTitle = {
  h2: {
    fontSize: '1.8rem',
    fontStyle: 'normal',
    ...fontWeight(600),
    lineHeight: '2.9rem',
    letterSpacing: '0',
    ...textAlign('left'),
    fontFamily: 'Montserrat',
  },
}

const warningContainer = {
  color: getAppColors('--color-error'),
  marginBottom: '20px',
}

const formControl = {
  width: '100%',
  marginBottom: '18px',
  fontFamily: 'Montserrat',
  '&:last-child': {
    marginBottom: '0',
  },
}

const inputLabel = {
  color: getAppColors('--color-textfield-label'),
  fontSize: '1.9rem',
  fontStyle: 'normal',
  ...fontWeight(700),
  ...textAlign('left'),
  fontFamily: 'Montserrat',
}

const inputField = {
  background: getAppColors('--color-gray-3'),
  borderRadius: '10px',
  padding: '5px 10px',
  fontFamily: 'Montserrat',
}

const dialogActions = {
  justifyContent: 'center',
  marginBottom: '15px',
}

const dialogActionButton = {
  color: getAppColors('--color-primary'),
  padding: '8px 20px',
  border: `2px solid ${getAppColors('--color-primary')}`,
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  ...fontWeight('bold'),
  fontSize: '1rem',
  lineHeight: '1.2rem',
  ...textAlign('center'),
  ...textTransform('uppercase'),
  marginRight: '5px',
  cursor: 'pointer',
  height: '50px',
  minWidth: '150px',

  '&.isDanger': {
    color: getAppColors('--color-error'),
    border: `2px solid ${getAppColors('--color-error')}`,
  },
}

const RadioButtonLabel = styled.div({
  fontSize: '1.2rem',
  lineHeight: '0.8em',
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  color: getAppColors('--color-textfield-label'),
})

const StyledRadio = styled(Radio)({
  padding: '4px',
})

const StyledRadioControl = styled(FormControlLabel)({
  marginLeft: '-7px',
})

type AlertDialogProps = {
  handleClose: () => void
  open: boolean
  selectedRow: AlertSession
  alertLocation: { value: string }
  action: string
  setMapVisible: Function
}

export default function SolveAlertDialog({ props }: { props: AlertDialogProps }) {
  const translation: TranslationGroup = trans.merge(TranslationKey.ALERT_HISTORY)
  const { handleClose, open, selectedRow, alertLocation, action, setMapVisible } = props
  const [description, setDescription] = useState<string>()
  const [message, setMessage] = useState('')
  const currentUser = getDataFromJWT()
  const orgsById = Orgs.use(({ orgsById }) => orgsById)

  let strings = {
    title: translation.resolve_this_alert,
    warning: translation.close_warning,
    error: translation.close_error,
    CTA: translation.resolve_alert,
  }
  if (action === ACTION_TYPE.ARRIVED) {
    strings = {
      title: translation.help_has_arrived,
      warning: translation.arrived_close_warning,
      error: translation.arrived_close_error,
      CTA: translation.mark_arrived,
    }
  }
  const submitDisabled =
    action !== ACTION_TYPE.ARRIVED && !!(!message.trim() || message.trim().length <= 1 || !description)

  const solveAlert = async (row: any, message: string) => {
    const token = localStorage.getItem(JWT_TOKEN) || ''
    const decoded = jwt_decode(token) as any
    const userId = JSON.parse(decoded.sub).user_id
    const orgId = alertLocation.value
    const selectedOrg = orgsById[orgId]
    const alertSourceId = selectedOrg?.props.legacy?.alertSourceId || null

    const alert = {
      alertEvent: {
        id: getUuidv4(),
        alertSessionId: row.id,
        alertSourceId,
        action: action || ACTION_TYPE.CLOSE,
        alertType: description,
        userId,
        orgId,
        timestamp: getUnixTime(new Date()),
        message: message || UPDATED_BY_DISPATCHER,
        beacons: [],
        role: 'responder',
        location: {},
      },
    }

    let skipRetry = false
    try {
      await postAlertRequest({
        endpoint: ALERT_API_ALERT_EVENT,
        data: alert,
        header: { org_id: alertLocation.value },
      })
      Orgs.reloadOrg(alertLocation.value)
      if (action === ACTION_TYPE.CLOSE) DetailsDrawer.close()
    } catch (e: any) {
      if (e?.response?.status == 422) {
        PopUpNotifications.fireError({
          content: `Server responded with a ${e.response.status} status code`,
        })

        return 422
      }

      sleep(500)
      console.log('retrying')

      // execute 1 retry
      try {
        await postAlertRequest({
          endpoint: ALERT_API_ALERT_EVENT,
          data: alert,
          header: { org_id: alertLocation.value },
        })
        Orgs.reloadOrg(alertLocation.value)
        if (action === ACTION_TYPE.CLOSE) DetailsDrawer.close()
      } catch (e: any) {
        // let error = navigator.onLine ? `${strings.error} + ${e}` : translation.network_error
        const error = e
        console.log({ e })
        PopUpNotifications.fireError({
          content: error,
        })
      }
    }
  }

  return (
    <Dialog open={!!open} onClose={handleClose} maxWidth="sm" fullWidth>
      <StyledDialogTitle css={dialogTitle}>{strings.title}</StyledDialogTitle>
      <DialogContent>
        <div css={warningContainer}>{strings.warning}</div>
        <form>
          <InputLabel css={inputLabel} htmlFor="alert-closing-email" shrink>
            {translation.certified_by}:
          </InputLabel>
          <FormControl css={formControl}>
            <Input
              id="alert-closing-email"
              css={inputField}
              value={`${currentUser?.first_name}${currentUser?.last_name ? ' ' + currentUser?.last_name : ''} ${
                currentUser?.email
              }`}
              disableUnderline
              disabled
            />
          </FormControl>
          {action === ACTION_TYPE.CLOSE && (
            <>
              <InputLabel css={inputLabel} htmlFor="alert-incident-description" shrink>
                {translation.describe_incident}*
              </InputLabel>
              <FormControl css={formControl}>
                <RadioGroup
                  name="alert-incident-description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                >
                  {[
                    ['guest', translation.guest_incident],
                    ['staff', translation.staff_incident],
                    ['false_alarm', translation.false_alarm],
                    ['test', translation.just_testing],
                  ].map(option => (
                    <StyledRadioControl
                      key={option[0]}
                      value={option[0]}
                      control={<StyledRadio />}
                      label={<RadioButtonLabel>{option[1]}</RadioButtonLabel>}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <InputLabel css={inputLabel} htmlFor="alert-closing-reason" shrink>
                {translation.resolution_reason}*:
              </InputLabel>
              <FormControl css={formControl}>
                <Input
                  id="alert-closing-reason"
                  css={inputField}
                  onChange={e => setMessage(e.target.value)}
                  value={message}
                  disableUnderline
                  placeholder={translation.enter_reason}
                />
              </FormControl>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions css={dialogActions}>
        <Button
          css={dialogActionButton}
          onClick={() => {
            setMapVisible(true)
            handleClose()
          }}
        >
          {translation.cancel}
        </Button>
        <Button
          css={dialogActionButton}
          onClick={async () => {
            solveAlert(selectedRow, message)
            // if (val === 422) return
            setMapVisible(false)
            handleClose()
          }}
          disabled={submitDisabled}
        >
          {strings.CTA}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
